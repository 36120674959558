<template>
  <div class="card" :class="[type && `card-${type}`]">
    <div class="card-image" v-if="$slots.image">
      <slot name="image"></slot>
    </div>
    <div
      class="card-header"
      v-if="$slots.header || title"
      :class="headerClasses"
    >
      <slot name="header"> </slot>
    </div>
    <div class="card-body" v-if="$slots.default">
      <slot></slot>
    </div>
    <div class="card-image" v-if="$slots['image-bottom']">
      <slot name="image-bottom"></slot>
    </div>
    <slot name="raw-content"></slot>
    <div class="card-footer" :class="footerClasses" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<style scoped>
.dropdown-label {
  --option-height: 30px;
  margin: -1px 1px 0px 1px;
}

.dropdown {
  border-radius: 12px;
}

.angle-down {
  margin: -10px 5px 0 0;
}

.entries-input {
  flex-wrap: wrap;
  padding: 12px 0px 0px 0px;
  display: inline;
  margin-left: 12px;
}

h2 {
  padding: 9px 0px 0px 22px;
}

.selectors {
  display: flex;
  justify-content: space-between;
  padding: 20px 8px 0px 14px;
}

.search-box {
  position: relative;
  top: -5px;
  left: 10px;
  border: none;
  border-radius: 12px;
  padding: 9px 5px 8px 15px;
}

.entries-dropdown {
  padding: 1px 0px 0px 0px;
  position: absolute;
  /* left: 100px; */
  top: 91px;
  display: flex;
  z-index: 100;
}

p {
  margin: 4px 0px;
  /* line-height: 6px; */
  /* position: relative;
  left: 69px;
  top: 4px; */
}

span {
  position: relative;
  top: 11px;
  left: -362px;
}

input {
  border-radius: 12px;
  padding: 6px;
}
</style>
<script>
// import VueDropdown from "vue-dynamic-dropdown";

export default {
  name: "card",
  components: {
    // VueDropdown
  },
  props: {
    title: {
      type: String,
      description: "Card title"
    },
    subTitle: {
      type: String,
      description: "Card subtitle"
    },
    type: {
      type: String,
      description: "Card type (e.g primary/danger etc)"
    },
    headerClasses: {
      type: [String, Object, Array],
      description: "Card header css classes"
    },
    bodyClasses: {
      type: [String, Object, Array],
      description: "Card body css classes"
    },
    footerClasses: {
      type: [String, Object, Array],
      description: "Card footer css classes"
    },
    dropDownVal: {
      type: [String]
    },
    data: Array,
    searchHeaders: Array,
    searchData: String,
    // table: Array,
    table: String
  },
  data: function() {
    return {
      dataList: [],
      search: "",
      tableSizeVal: 10,
      config: {
        options: [
          {
            value: 10
          },
          {
            value: 25
          },
          {
            value: 50
          },
          {
            value: 100
          }
        ],
        prefix: 10,
        width: 140,
        backgroundColor: "white",
        tableLength: false
      }
    };
  },
  computed: {
    checkTable: {
      get: function() {
        return this.tableLength;
      },
      set: function(val) {
        console.log(val, "computed val");
        this.tableLength = val;
      }
    }
  },
  methods: {
    setDropDown(val) {
      this.config.prefix = val.value;
      this.$emit("getDropDownVal", val.value);
    },
    SearchFunc() {
      console.log(this.search);
      this.$emit("SearchFunction", this.search);
    }
  },
  mounted() {
    this.dataList = [];
  }
};
</script>
