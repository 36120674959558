import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import NotFound from "@/pages/NotFoundPage.vue";
import Vue from "vue";
import Router from "vue-router";
import { isAuthenticated } from "./helpers/auth.js";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base:
    process.env.NODE_ENV === "development"
      ? process.env.BASE_URL + "admin"
      : process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      redirect: "/dashboard",
      component: DashboardLayout,
      beforeEnter: (to, from, next) => {
        if (!isAuthenticated()) {
          next({
            path: "/login"
          });
        } else next();
      },
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () =>
            import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue")
        },
        {
          path: "profile",
          name: "profile",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/Profile.vue")
        },
        {
          path: "cms",
          name: "cms",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/Cms.vue")
        },
        {
          path: "verification",
          name: "planner verification",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/Verification.vue")
        },
        {
          path: "VerificationApprovals",
          name: "verification Approvals",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/VerificationApprovals.vue")
        },
        {
          path: "VerificationDenials",
          name: "verification Denials",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/VerificationDenials.vue")
        },
        {
          path: "admin-fee",
          name: "admin-fees",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/AdminFee.vue")
        },
        {
          path: "custom-invoice",
          name: "custom-invoice",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/CustomInvoice.vue")
        },
        {
          path: "table-list",
          name: "table-list",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/TableList.vue")
        },
        {
          path: "view-all",
          name: "info",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Dashboard/ViewAll.vue"
            )
        },
        {
          path: "details/:title",
          name: "details",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/Details.vue")
        },
        {
          path: "edit/:title",
          name: "edit",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/Edit.vue")
        },
        {
          path: "sellers-fee",
          name: "planner fee",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/SellersFee.vue")
        },
        {
          path: "event-fee",
          name: "event fee",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/EventFees.vue")
        },
        {
          path: "banner-edit",
          name: "banner edit",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/BannerEdit.vue")
        },
        {
          path: "banner-create",
          name: "banner create",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/BannerCreate.vue")
        },
        {
          path: "banner-details",
          name: "banner details",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/BannerDetails.vue")
        },
        {
          path: "ticket-ad",
          name: "ticket ad",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/TicketAd.vue")
        },
        {
          path: "failed-transactions",
          name: "failed-transactions",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/FailedTransactions/FailTransactions.vue"
            )
        },
        {
          path: "process-refund",
          name: "process-refund",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Tickets/ProcessRefund.vue"
            ),
          props: true
        },
        {
          path: "reset-password",
          name: "Reset Password",
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Dashboard/ResetPassword.vue"
            )
        },
        {
          path: "edit-company",
          name: "Edit Company",
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Dashboard/EditCompany.vue"
              )
        },
        {
          path: "promocodes",
          name: "Promo Codes",
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Events/PromoCodes.vue"
            )
        },
        {
          path: "comps",
          name: "Comps",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/Events/Comps.vue")
        },
        {
          path: "payment",
          name: "Payment",
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Financials/Payment.vue"
            )
        },
        {
          path: "planners",
          name: "planners",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Users/Planners.vue"
            )
        },
        {
          path: "brands",
          name: "brands",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Users/Brands.vue"
              )
        },
        {
          path: "buyers",
          name: "buyers",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/Users/Buyers.vue")
        },
        {
          path: "promoters",
          name: "promoters",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/Users/Promoters.vue")
        },
        {
          path: "create-user",
          name: "Create User",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/Users/CreateUser.vue")
        },
        {
          path: "pvs",
          name: "pvs",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/Users/PVs.vue")
        },
        {
          path: "tickets",
          name: "purchases",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Tickets/Tickets.vue"
            )
        },
        {
          path: "comps-willcall",
          name: "comps",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Tickets/CompsWillCall.vue"
              )
        },
        {
          path: "printed-tickets",
          name: "printed tickets",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Tickets/PrintedTickets.vue"
            )
        },
        {
          path: "user-refunds",
          name: "Pending Refunds",
          component: () =>
            import(
              /* webpackChunkName: "user-refunds" */ "@/pages/Tickets/UserRefund.vue"
            )
        },
        {
          path: "open-products",
          name: "Products",
          component: () =>
            import(
              /* webpackChunkName: "open-products" */ "@/pages/Tickets/Products.vue"
            )
        },
        {
          path: "open-guest-list",
          name: "Guest List",
          component: () =>
            import(
              /* webpackChunkName: "open-guest-list" */ "@/pages/Tickets/GuestList.vue"
            )
        },
        {
          path: "manage-events",
          name: "manage events",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Events/ManageEvents.vue"
            )
        },
        {
          path: "past-events",
          name: "past events",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Events/PastEvent.vue"
            )
        },
        {
          path: "cancelled-events",
          name: "cancelled events",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Events/CancelledEvents.vue"
            )
        },
        {
          path: "proof-read-events",
          name: "proof read events",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Events/ProofReadEvents.vue"
            )
        },
        {
          path: "create-event",
          name: "create event",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Events/CreateEvent.vue"
            )
        },
        {
          path: "financials",
          name: "financials",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Financials/ManageFinancials.vue"
            )
        },
        {
          path: "financials-breakdown",
          name: "financials breakdown",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Financials/FinancialsBreakdown.vue"
            )
        },
        {
          path: "failed-transactions",
          name: "failed transactions",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/FailedTransactions/FailTransactions.vue"
            )
        },
        {
          path: "advertisement",
          name: "advertisement",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/Advertisement.vue")
        },
        {
          path: "admin-create-tickets",
          name: "Tix",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/FailedTransactions/AdminCreateTickets.vue"
            )
        },
        {
          path: "covid-relief",
          name: "Covid Relief",
          component: () =>
            import(/* webpackChunkName: "common" */ "@/pages/CovidRelief.vue")
        },
        {
          path: "url-redirects",
          name: "Url Redirects",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Redirects/UrlRedirects.vue"
            )
        },
        {
          path: "url-redirects/create",
          name: "Url Redirects",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Redirects/UrlRedirectsCreate.vue"
            )
        },
        {
          path: "url-redirects/edit/:id",
          name: "Url Redirects",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Redirects/UrlRedirectsEdit.vue"
            )
        },
        {
          path: "/white-label",
          name: "White Label",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/White-Label/WhiteLabel.vue"
            )
        },
        {
          path: "/white-label/create",
          name: "Create White Label",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/White-Label/WhiteLabelCreate.vue"
            )
        },
        {
          path: "/white-label/edit/:id",
          name: "Edit White Label",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/White-Label/WhiteLabelEdit.vue"
            )
        },
        {
          path: "/test-emails",
          name: "Test Emails",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/TestEmails.vue"
            )
        },{
          path: "/email-logs",
          name: "Email Logs",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/EmailLogs.vue"
            )
        }, {
          path: "/tool-tip",
          name: "Tooltip",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Tooltip/Tooltip.vue"
            )
        }, {
          path: "/sub-admin",
          name: "sub-admin",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/Users/SubAdmin.vue"
            )
        }, {
          path: "/promoter-sales",
          name: "promoter sales",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "@/pages/PromoterSales.vue"
              )
        }
      ]
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "common" */ "../pages/Login.vue"),
      props: true
    },
    { path: "*", component: NotFound }
  ]
});

export default router;
