import axios from "axios";
import decode from "jwt-decode";

export const SESSION_COOKIE = "asession";

const REST_ENDPOINT = process.env.VUE_APP_SERVICE_URL;

export function getCookies() {
  let cookie = document.cookie.split(";");
  let cookies = {};

  cookie.forEach(key => {
    let keyVal = key.trim().split("=");
    cookies[keyVal[0]] = keyVal[1];
  });
  return cookies;
}

export function getSessionCookie() {
  return getCookies()[SESSION_COOKIE];
}

export async function createSession(username, password, app) {
  let data;

  await axios
    .post(`${REST_ENDPOINT}admin-authenticate`, {
      name: username,
      password: password
    })
    .then(resp => {
      data = resp.data;
    });

  if (data.error) throw data.error;
  else {
    // get session data and store it in app data
    let decoded = decode(data);
    // app.user.name = decoded.name
    app.user.email = decoded.email;
    app.user.isAuthenticated = true;
    app.session_id = decoded.session_id;

    // create a cookie for session data
    document.cookie = `${SESSION_COOKIE}=${data}`;
  }
}

export function destroySession(app) {
  axios.post(`${REST_ENDPOINT}auth/deauthenticate`).then(() => {
    app.user.isAuthenticated = false;
    app.user.name = null;
    app.user.email = null;
    app.session_id = null;

    document.cookie = `${SESSION_COOKIE}=${getSessionCookie()}; expires=Thu, 18 Dec 2013 12:00:00 UTC`;
  });
}

export function setAuthToken() {}

export function getAuthToken() {
  let cookies = getCookies();
  let session = cookies[SESSION_COOKIE] ? cookies[SESSION_COOKIE] : false;

  return session;
}

export function clearAuthToken() {}

export function isAuthenticated() {
  let cookies = getCookies();
  // return cookies[SESSION_COOKIE] && store.state.user.isAuthenticated
  return cookies[SESSION_COOKIE];
}
