<template>
  <div id="app">
    <!-- <notifications></notifications> -->
    <router-view
      :key="$route.fullPath"
      v-on:logout="logout"
      v-on:login="login"
      :bus="bus"
    >
    </router-view>
  </div>
</template>

<script>
import {
  createSession,
  destroySession,
  getSessionCookie,
  isAuthenticated,
  SESSION_COOKIE
} from "./router/helpers/auth.js";
import Vue from "vue";
import decode from "jwt-decode";

export default {
  data() {
    return {
      bus: new Vue()
    };
  },
  computed: {
    //comment
    session_id: {
      get() {
        return this.$store.state.session_id;
      },
      set(id) {
        this.$store.state.session_id = id;
        return id;
      }
    },
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    disableRTL() {
      if (!this.$rtl.isRTL) {
        this.$rtl.disableRTL();
      }
    },
    toggleNavOpen() {
      let root = document.getElementsByTagName("html")[0];
      root.classList.toggle("nav-open");
    },
    async logout() {
      let self = this;
      await destroySession(self);
      this.$router.push("/login");
    },
    async login(obj) {
      let self = this;

      try {
        await createSession(obj.username, obj.password, self);
        this.$router.push("/dashboard");
      } catch (e) {
        this.bus.$emit("loginError", e);
      }
    }
  },
  async mounted() {
    this.$watch("$route", this.disableRTL, { immediate: true });
    this.$watch("$sidebar.showSidebar", this.toggleNavOpen);

    var cookie = document.cookie.split("; ");
    var cookies = {};
    cookie.forEach(el => {
      let keyvar = el.split("=");
      cookies[keyvar[0].trim()] = keyvar[1];
    });

    this.$axios.interceptors.request.use(
      config => {
        config.headers["Authorization"] = `Bearer ${getSessionCookie()}`;
        return config;
      },
      error => Promise.reject(error)
    );

    this.$axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response.status === 401) {
          const cookie = getSessionCookie();
          if (cookie) {
            document.cookie = `${SESSION_COOKIE}=${cookie}; expires=Thu, 18 Dec 2013 12:00:00 UTC`;
          }

          this.$store.state.user.isAuthenticated = false;

          if (!this.$route.meta.allowAnonymous) {
            this.$router.push("/");
            // TODO: Once all the loader stuff is done we need to handle this a bit better
            this.$loader.stop();
          }
        }

        return Promise.reject(error);
      }
    );

    let self = this;

    if (document.referrer == "https://admin.incredevent.com/") {
      await createSession(this.$route.query.login, null, self, "admin");
    }

    if (cookies[SESSION_COOKIE]) {
      this.$loader.start();
      const decoded = decode(cookies[SESSION_COOKIE]);

      this.$axios.post("/auth/verify-admin").then(response => {
        const data = response.data;
        if (data.authorized) {
          this.$store.state.session_id = decoded.session_id;
          this.$store.state.user = data.user;
          this.$store.state.user.isAuthenticated = true;
          this.$store.state.user.first_name = data.user.first_name;
          this.$store.state.user.name =
            data.user.first_name + " " + data.user.last_name;
          this.$store.state.user.email = data.user.email;
          this.$store.state.user.coordinator = decoded.coordinator;
          this.$store.state.profileComplete = decoded.profileComplete;

          // const notificationsUrl =
          //   process.env.VUE_APP_NOTIFICATIONS_SOCKET +
          //   "?session_id=" +
          //   decoded.session_id;

          // this.$notifications.connect(notificationsUrl);

          // this.$axios.get("/notifications/recent").then(response => {
          //   this.$notifications.init(response.data.notifications);
          // });

          let q = this.$route.query;
          if (q.redirect) {
            this.$router.replace(q.redirect);
          } else if (this.$route.name === "home") {
            this.$router.replace("/");
          }
        } else {
          const route = this.$route;
          if (!route.meta.allowAnonymous && !isAuthenticated()) {
            this.$router.push({
              path: "/",
              query: { redirect: route.fullPath }
            });
          }
        }

        this.authenticated = true;
        this.$store.state.authenticated = true;
        this.$loader.stop();
      });
    } else {
      this.authenticated = true;
      this.$store.state.authenticated = true;
      const route = this.$route;
      this.$router.push("/");
      // if (!route.meta.allowAnonymous && !isAuthenticated()) {
      //   this.$router.push({
      //     path: "/",
      //     query: { redirect: route.fullPath },
      //   });
      // }
    }
  }
};
</script>

<style lang="css">
@import "./assets/css/dropDownStyle.css";

/*
input,
select,
textarea {
  background-color: white;
} */
</style>
