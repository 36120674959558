<template>
  <div class="table-wrap">
    <v-app>
      <v-card>
        <v-card-title>
          {{ title }}
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-btn @click="searchData" color="primary" class="searchBtn"
            >Search</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="#44b678"
            class="mr-2"
            v-if="create"
            @click="$emit('create')"
          >
            Create
          </v-btn>

          <JsonCSV :data="data" :name="title.toLowerCase() + '.csv'">
            <v-btn color="primary"> Export to CSV</v-btn>
          </JsonCSV>
        </v-card-title>
        <v-tabs v-if="tabs">
          <v-tab
            v-for="tab in tabs"
            :key="tab.link"
            @click="onClickTab(tab.link)"
          >
            {{ tab.text }}
          </v-tab>
        </v-tabs>

        <v-data-table
          :headers="columns"
          :items="data"
          :loading="loading"
          :page="loadLastPage"
          :server-items-length="dataCount"
          @update:page="onPagination"
          @update:items-per-page="onPageSizeChange"
          class="elevation-1"
          :footer-props="footerProps"
          :sort-by.sync="sortBy"
          @update:sort-by="onSortBy"
          :sort-desc="[true | false]"
        >
          <template v-slot:[`item.coordVerified`]="{ item }">
            <v-checkbox
              v-model="item.coordVerified"
              dense
              @change="onVerify(item)"
            ></v-checkbox>

            <!-- <v-icon color="teal lighten-1" @click="$emit('login', item)"> -->
            <!-- </v-icon> -->
            <!-- <v-icon>mdi-file-document-outline</v-icon> -->
            <!-- <a v-on:click="$emit('generateTicket', item)" -->
          </template>
          <template #item.eventName="{ item }">
            <a
              style="color: white;font-weight: 400;"
              :href="`https://incredevent.com/${item.url}`"
              target="_blank"
              >{{ item.eventName }}</a
            >
          </template>
          <!-- EventFinancials -->
          <template v-slot:[`item.payoutPayment`]="{ item }">
            <v-icon color="teal lighten-1" @click="$emit('payment', item)">
              mdi-cash
            </v-icon>
          </template>

          <!-- Redirects -->
          <template v-slot:[`item.edit-redirect`]="{ item }">
            <v-icon color="teal lighten-1" @click="$emit('edit', item)">
              mdi-pencil
            </v-icon>
            <!-- <v-icon color="teal lighten-1" @click="$emit('login', item)"> -->
            <!-- </v-icon> -->
            <!-- <v-icon>mdi-file-document-outline</v-icon> -->
            <!-- <a v-on:click="$emit('generateTicket', item)" -->
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <v-icon color="teal lighten-1" @click="$emit('edit', item)">
              mdi-pencil
            </v-icon>
            <!-- <v-icon color="teal lighten-1" @click="$emit('login', item)"> -->
            <!-- </v-icon> -->
            <!-- <v-icon>mdi-file-document-outline</v-icon> -->
            <!-- <a v-on:click="$emit('generateTicket', item)" -->
          </template>
          <template v-slot:[`item.delete-redirect`]="{ item }">
            <v-icon color="teal lighten-1" @click="$emit('delete', item)">
              mdi-delete
            </v-icon>
          </template>

          <!-- Orders -->
          <template v-slot:[`item.status`]="{ item }">
            <!-- <v-icon color="teal lighten-1" @click="deleteOrder(item)"> -->
            <v-icon
              color="teal lighten-1"
              @click="$emit('change-status', { item, status: 'HIDE' })"
              v-if="item.status === 'VISIBLE'"
            >
              mdi-eye
            </v-icon>
            <v-icon
              color="teal lighten-1"
              @click="$emit('change-status', { item, status: 'SHOW' })"
              v-else-if="item.status === 'HIDDEN'"
            >
              mdi-eye-off
            </v-icon>
            <span v-else>
              {{ item.status ? item.status.toUpperCase() : "" }}</span
            >

            <!-- </v-icon> -->
          </template>

          <template v-slot:[`item.delete-order`]="{ item }">
            <!-- <v-icon color="teal lighten-1" @click="deleteOrder(item)"> -->
            <v-icon color="teal lighten-1" @click="$emit('delete-order', item)">
              mdi-delete
            </v-icon>
          </template>

          <!-- Manage Events -->
          <template v-slot:[`item.event-promo`]="{ item }">
            <v-icon color="teal lighten-1" @click="$emit('promoCodes', item)">
              mdi-ticket-percent
            </v-icon>
          </template>
          <template v-slot:[`item.event-comp`]="{ item }">
            <v-icon color="teal lighten-1" @click="$emit('comps', item)">
              mdi-ticket
            </v-icon>
          </template>
          <template v-slot:[`item.event-cancel`]="{ item }">
            <v-icon color="teal lighten-1" @click="$emit('cancel', item)">
              mdi-close-circle
            </v-icon>
            <!-- <v-icon color="teal lighten-1" @click="$emit('login', item)"> -->
            <!-- </v-icon> -->
            <!-- <v-icon>mdi-file-document-outline</v-icon> -->
            <!-- <a v-on:click="$emit('generateTicket', item)" -->
          </template>

          <!-- ProofRead Event -->
          <template v-slot:[`item.edit-event`]="{ item }">
            <v-icon color="teal lighten-1" @click="$emit('edit', item)">
              mdi-pencil
            </v-icon>
            <!-- <v-icon color="teal lighten-1" @click="$emit('login', item)"> -->
            <!-- </v-icon> -->
            <!-- <v-icon>mdi-file-document-outline</v-icon> -->
            <!-- <a v-on:click="$emit('generateTicket', item)" -->
          </template>
          <template v-slot:[`item.event-proof-complete`]="{ item }">
            <v-checkbox
              v-model="item.coordVerified"
              @change="onProofComplete(item)"
            ></v-checkbox>

            <!-- <v-icon color="teal lighten-1" @click="$emit('login', item)"> -->
            <!-- </v-icon> -->
            <!-- <v-icon>mdi-file-document-outline</v-icon> -->
            <!-- <a v-on:click="$emit('generateTicket', item)" -->
          </template>

          <!-- Login -->

          <template v-slot:[`item.login`]="{ item }">
            <v-icon color="teal lighten-1" @click="$emit('login', item)">
              mdi-login
            </v-icon>
          </template>
          <!-- Changes activated -->x
          <template v-slot:[`item.activated`]="{ item }">
            <label class="label">
              <input
                type="checkbox"
                v-model="item.activated"
                v-bind:disabled="item.activated"
                @click="onVerify(item)"
              />
            </label>
          </template>

          <template v-slot:[`item.login-worre`]="{ item }">
            <v-icon color="teal lighten-1" @click="$emit('login-worre', item)">
              mdi-login
            </v-icon>
          </template>

          <!-- Users -->
          <template v-slot:[`item.resetPassword`]="{ item }">
            <v-icon
              color="teal lighten-1"
              @click="$emit('resetPassword', item)"
            >
              mdi-repeat
            </v-icon>
          </template>
          <template v-slot:[`item.accountLock`]="{ item }">
            <v-icon
              color="teal lighten-1"
              v-if="item.accountLock"
              @click="$emit('lock', { user: item, action: 'unlock' })"
            >
              mdi-lock
            </v-icon>
            <v-icon
              v-else
              color="teal lighten-1"
              @click="$emit('lock', { user: item, action: 'lock' })"
            >
              mdi-lock-open-variant
            </v-icon>
          </template>

          <!-- Tickets -->
          <template v-slot:[`item.refunded`]="{ item }">
            <v-icon color="teal lighten-1" v-if="item.refunded">
              mdi-check
            </v-icon>
            <v-icon color="teal lighten-1" v-else-if="item.refundAmount">
              mdi-minus
            </v-icon>
            <v-icon color="teal lighten-1" v-else> mdi-close</v-icon>
          </template>
          <template v-slot:[`item.protecht`]="{ item }">
            <v-icon color="teal lighten-1" v-if="item.protecht">
              mdi-check
            </v-icon>
            <v-icon color="teal lighten-1" v-else> mdi-close</v-icon>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div v-if="item.status === 'Open'">
              <v-icon color="teal lighten-1" @click="$emit('approved', item)">
                mdi-check
              </v-icon>

              <v-icon color="teal lighten-1" @click="$emit('rejected', item)">
                mdi-cancel
              </v-icon>
            </div>
            <div v-if="item.status === 'Closed'">
              <v-icon
                color="teal lighten-1"
                v-if="!item.approved"
                @click="$emit('approved', item)"
              >
                mdi-check
              </v-icon>

              <v-icon
                color="teal lighten-1"
                v-if="item.approved"
                @click="$emit('rejected', item)"
              >
                mdi-cancel
              </v-icon>
            </div>
          </template>
          <template v-slot:[`item.genTic`]="{ item }">
            <v-icon
              v-if="item.ticketType !== 'VIRTUAL'"
              color="teal lighten-1"
              @click="$emit('generateTicket', item)"
            >
              mdi-file-document
            </v-icon>
            <v-icon
              v-else
              color="teal lighten-1"
              @click="$emit('copyMagicLink', item)"
            >
              mdi-link
            </v-icon>
            <!-- <v-icon>mdi-file-document-outline</v-icon> -->
            <!-- <a v-on:click="$emit('generateTicket', item)" -->
          </template>
          <template v-slot:[`item.attendeeLink`]="{ item }">
            <v-icon
              color="teal lighten-1"
              @click="$emit('copyAttendeeLink', item)"
            >
              mdi-link
            </v-icon>
          </template>
          <template v-slot:[`item.updateTier`]="{ item }">
            <v-icon color="teal lighten-1" @click="$emit('updateTier', item)">
              mdi-pencil
            </v-icon>
            <!-- <v-icon>mdi-file-document-outline</v-icon> -->
            <!-- <a v-on:click="$emit('generateTicket', item)" -->
          </template>
          <template v-slot:[`item.assignPromoter`]="{ item }">
            <v-icon
              color="teal lighten-1"
              @click="$emit('assignPromoter', item)"
            >
              mdi-account-plus
            </v-icon>
            <!-- <v-icon>mdi-file-document-outline</v-icon> -->
            <!-- <a v-on:click="$emit('generateTicket', item)" -->
          </template>
          <template v-slot:[`item.history`]="{ item }">
            <v-icon color="teal lighten-1" @click="$emit('history', item)">
              mdi-history
            </v-icon>
          </template>
          <template v-slot:[`item.details`]="{ item }">
            <v-icon color="teal lighten-1" @click="$emit('details', item)">
              mdi-account-details
            </v-icon>
          </template>
          <template v-slot:[`item.processrefund`]="{ item }">
            <v-icon
              color="red"
              v-if="item.refunded"
              @click="$emit('refund', { ticket: item, page: page })"
            >
              mdi-currency-usd
            </v-icon>
            <v-icon
              color="yellow"
              v-else-if="item.refundAmount"
              @click="$emit('refund', { ticket: item, page: page })"
            >
              mdi-currency-usd
            </v-icon>
            <v-icon
              color="teal lighten-1"
              v-else
              @click="$emit('refund', { ticket: item, page: page })"
            >
              mdi-currency-usd
            </v-icon>
            <!-- <v-icon>mdi-file-document-outline</v-icon> -->
          </template>
        </v-data-table>
      </v-card>
    </v-app>
  </div>
</template>

<!-- <div class="pagination" v-if="dataCount > tableSize">
      <p
        class="from"
        :class="{ inactive: page <= 1 }"
        v-on:click="paginate(-1, true)"
      >
        <font-awesome-icon icon="chevron-left" />
      </p>
      <p
        v-for="_page in pageButtons"
        :key="_page"
        :class="{ active: _page === page }"
        v-on:click="paginate(_page)"
      >
        {{ _page }}
      </p>
      <p
        class="to"
        :class="{ inactive: page >= pages }"
        v-on:click="paginate(1, true)"
      >
        <font-awesome-icon icon="chevron-right" />
      </p>
    </div> -->

<script>
import Axios from "axios";
// import { debounce } from "debounce";
import jsPDF from "jspdf";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faFilePdf,
  faLock,
  faLockOpen,
  faCheckSquare,
  faInfoCircle,
  faChevronLeft,
  faChevronRight,
  faCog,
  faRedoAlt
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import JsonCSV from "vue-json-csv";
import { trim } from "lodash";
import verify from "../../../shared/views/Verify.vue";

library.add(faFilePdf);
library.add(faLock);
library.add(faLockOpen);
library.add(faCheckSquare);
library.add(faInfoCircle);
library.add(faRedoAlt);
library.add(faCog);
library.add(faChevronLeft);
library.add(faChevronRight);

function debounce(func, wait, immediate) {
  var timeout;

  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default {
  name: "base-table",
  components: {
    FontAwesomeIcon,
    JsonCSV
  },
  props: {
    // Verify changes
    user: Object, // Pass the user object as a prop

    tabs: { default: () => [], type: Array },
    create: {
      type: Boolean,
      default: false
    },
    endpoint: String,
    params: {
      default: () => {
        return {};
      },
      type: Object
    },
    lastPage: { type: Number, default: 1 },
    title: { type: String, default: "" },
    approveBtn: Boolean,
    blackListBtn: Boolean,
    columns: {
      type: Array,
      default: () => [],
      description: "Table columns"
    },

    type: {
      type: String, // striped | hover
      default: "",
      description: "Whether table is striped or hover type"
    },
    theadClasses: {
      type: String,
      default: "",
      description: "<thead> css classes"
    },
    tbodyClasses: {
      type: String,
      default: "",
      description: "<tbody> css classes"
    },
    pendingInterests: Array,
    blackListedInterests: Array,
    url: String,
    addToQuery: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      interests: [],
      page: 1,
      data: [],
      dataCount: 0,
      loading: true,
      pageSize: 10,
      checked: false,

      // isChecked: this.isVerified,

      sort: 1,
      sortVal: ["name"],
      search: "",
      footerProps: {
        "items-per-page-options": [10, 25, 50, 100],
        "show-current-page": true,
        "show-first-last-page": true
      },
      sortBy: [
        // sortVal: 'name',
        // sort: 'desc'
      ]
      // loading: true,
    };
  },
  watch: {
    search() {
      if (this.search == "") {
        this.loading = true;
        this.refetchData();
      }
    }
  },
  computed: {
    verify() {
      return verify;
    },
    // Verify changes
    isVerified() {
      // Replace this with your actual verification check based on the user object
      return this.user && this.user.isVerified === true;
    },

    tableClass() {
      return this.type && `table-${this.type}`;
    },
    loadLastPage() {
      if (this.lastPage > 1) {
        this.page = this.lastPage;
        return this.lastPage;
      } else {
        return 1;
      }
    },
    pageButtons() {
      if (this.pages <= 4) {
        return [1, 2, 3, 4].slice(0, this.pages);
      }

      if (this.page > this.pages - 5) {
        return [
          this.pages - 4,
          this.pages - 3,
          this.pages - 2,
          this.pages - 1,
          this.pages
        ];
      }

      return [this.page, this.page + 1, "...", this.pages - 1, this.pages];
    },
    pages() {
      if (!this.data) return 0;
      // return Math.ceil(this.data.length / this.tableSize.value);
      return Math.ceil(this.dataCount / this.tableSize);
    },
    query() {
      return {
        limit: this.pageSize,
        skip: (this.page - 1) * this.pageSize,
        search: this.search,
        sort: this.sort,
        sortVal: this.sortVal
      };
    }
  },
  async created() {
    let searchQuery = this.$route.query.search
      ? trim(this.$route.query.search)
      : null;
    if (searchQuery) {
      this.search = searchQuery;
    }

    this.fetchOnLoad();
  },

  mounted() {},
  methods: {
    searchData() {
      this.loading = true;
      this.refetchData();
    },
    onSortBy(val) {
      this.loading = true;
      if (val.length == 0) {
        this.sort = 1;
      } else {
        this.sortVal = val;
        this.sort = -1;
      }
      this.fetchOnLoad();
    },
    onClickTab(tab) {
      this.loading = true;

      this.endpoint = tab;
      this.fetchOnLoad();
    },
    onVerify(item) {
      this.$emit("verify", item);
    },
    onProofComplete(item) {
      this.$emit("completed", item);
      // this.refetchData();
    },

    refetchData: debounce(function() {
      this.page = 1;
      this.fetchOnLoad();
    }, 400),

    async fetchData() {
      let newQuery = { ...this.params, ...this.query };
      if (Object.keys(this.params).length !== 0) {
        const { data } = await this.$axios.post(this.endpoint, newQuery);
        return data;
      } else {
        const { data } = await this.$axios.post(this.endpoint, this.query);
        return data;
      }
    },
    async fetchOnLoad() {
      const data = await this.fetchData();
      this.data = data.items;
      this.dataCount = data.count;

      let search = trim(this.search);
      let fullPath = this.$route.fullPath;

      if (search) {
        setTimeout(() => {
          if (this.addToQuery) {
            if (!fullPath.includes("search") && !this.$route.query.search) {
              let currentRoute = `${this.$route.path}?search=${search}`;

              this.$router.push(currentRoute);
            } else {
              let currentRoute = `${this.$route.path}?search=${search}`;
              if (this.$route.fullPath !== currentRoute) {
                this.$router.replace(currentRoute);
              }
            }
          }

          this.updateTable(0, this.pageSize, data.items);
        }, 540);
      }

      this.loading = false;
    },
    onPageSizeChange(pagesize) {
      this.loading = true;
      this.pageSize = pagesize;
      this.fetchOnLoad();
      this.page = 1;
      // this.$emit("changePageSize", {
      //   pagesize: pagesize,
      // });
    },
    onPagination(page) {
      this.loading = true;
      console.log(page);
      this.page = page;
      this.fetchOnLoad();
      // this.$emit("paginate", {
      //   page: page,
      // });
    },
    updateTable(start, end, arrayToLoop) {
      let arrayToDisplay = [];
      for (let x = start; x <= end - 1; x++) {
        if (arrayToLoop[x] != null) {
          arrayToDisplay.push(arrayToLoop[x]);
        }
      }

      this.data = arrayToDisplay;
    },
    validate(inputid) {
      var allinputs = document.getElementsByClassName("restrict");
      var individualinput = document.getElementById(inputid);
      let length = this.data.length;

      // for(let i = 0;i < allinputs.length; i++) {
      //   const element = allinputs[i];
      // // }(element => {
      // //   var inputValue=element.value;
      // // if(parseInt(inputValue)>length)
      // // {
      // //   element.value =length;
      // //   return false;
      // // }
      // // });

      // }
    },
    editImage() {
      this.$emit("imgedit");
    },
    details(item) {
      this.$emit("detailed", item);
    },
    edit(item) {
      this.$emit("edited", item);
    },
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    approve(interest) {
      axios
        .post(`${process.env.VUE_APP_SERVICE_URL}approve-interest`, {
          interest: interest.interest
        })
        .then(response => {
          this.$emit("approved", interest);
        });
    },
    blackList(interest) {
      axios
        .post(`${process.env.VUE_APP_SERVICE_URL}blacklist-interest`, {
          interest: interest.interest
        })
        .then(response => {
          this.$emit("blacklisted", interest);
        });
    },
    moreInfo(modalId) {
      document.getElementById(modalId).style.display = "flex";
    },
    closeModal(modalId, action, userId) {
      document.getElementById(modalId).style.display = "none";
      if (action == "lock") {
        // api call to lock user here
        Axios.post(`${process.env.VUE_APP_SERVICE_URL}lock-user`, {
          userId: userId
        }).then(response => {
          this.data.forEach(ele => {
            if (ele.id == userId) {
              ele.accountLock = true;
            }
          });
        });
      } else if (action == "unlock") {
        // api call to unlock user here
        Axios.post(`${process.env.VUE_APP_SERVICE_URL}unlock-user`, {
          userId: userId
        }).then(response => {
          this.data.forEach(ele => {
            if (ele.id == userId) {
              ele.accountLock = false;
            }
          });
        });
      } else if (action == "verify") {
        Axios.post(`${process.env.VUE_APP_SERVICE_URL}verify-user`, {
          userId: userId
        }).then(response => {
          let index;
          for (let x = 0; x < this.data.length; x++) {
            if (this.data[x].id == userId) {
              index = x;
            }
          }
          this.data.splice(index, 1);
        });
      }
    },
    resetPassword(id) {
      this.$router.push({ name: "Reset Password", params: { id: id } });
    },
    cancelEvent(event) {},
    jspdf() {
      let doc = new jsPDF();
      let canvas = document.getElementById("canvas");
      let ctx = canvas.getContext("2d");
      const image = new Image(100, 100);
      image.onload = drawImage;
      image.src = document.getElementById("img").src;

      function drawImage() {
        canvas.width = this.naturalWidth;
        canvas.height = this.naturalHeight;
        ctx.drawImage(this, 0, 0);
        this.dataUrl = canvas.toDataURL();
        doc.setFontSize(12);
        doc.addImage(this.dataUrl, "PNG", 0, 0, 212, 290);

        // Axios.get(`${process.env.VUE_APP_SERVICE_URL}/get-events`)
        // .then(resp => {
        // let respData = resp.data;
        // respData.forEach(data => {
        //   if(data.url == ticket.event_url) {
        //     let startDate = moment(data.start.$date.$numberLong, "x").format('dddd, MMMM Do YYYY');
        //     let endDate = moment(data.end.$date.$numberLong, "x").format('dddd, MMMM Do YYYY');
        //     let realTier = ticket.ticket_tier;

        //     for(var a = 0; a < ticket.ticket_count; a++) {
        //       for(let t = 0; t < data.tiers.length; t++) {
        //         if(data.tiers[t].name == realTier) {
        //           // PRICE
        //           doc.text("$" + data.tiers[t].price + ".00", 81, 67);
        //         }
        //       }
        //       // EVENT
        // doc.text("data.name", 27, 79)
        //       // START DATE
        //       doc.text(startDate, 37.5, 90.5)
        //       // TICKET#
        //       doc.text((a + 1).toString(), 13.2, 55)

        //       // VALIDATION
        //       doc.text(self.ticket[x].validation_short, 41, 55)
        //       // INVOICE
        //       // doc.text(self.ticket[x].invoice, 85.3, 55)
        //       // TYPE
        //       doc.setFontSize(11);
        //       doc.text(ticket.type, 25, 67)
        //       // ENDDATE
        //       doc.setFontSize(12)
        //       doc.text(endDate, 34, 103)
        //       // LOCATION
        //       doc.text(ticket.pickup_location, 34, 115.5)
        //       //SOLDBY
        //       doc.text(ticket.name, 39, 129.2)
        //       if(a < ticket.ticket_count - 1) {
        //         doc.addPage();
        //         doc.addImage(this.dataUrl, 'PNG', 0, 0, 212, 290);
        //       }
        //     }
        //     window.open(doc.output('bloburl'), '_blank')
        //   }
        // })
        window.open(doc.output("bloburl"), "_blank");
      }
    },
    promoCodes() {
      this.$router.push("promocodes");
    },
    comps() {
      this.$router.push("comps");
    },
    payment() {
      this.$router.push("payment");
    },
    userLogin(user, site) {
      window.open(
        "https://" + site + ".incredevent.com/?login=" + user.email,
        "_blank"
      );
    }
  }
};
</script>
<style scoped lang="less">
.searchBtn {
  margin-left: 20px;
}

.table-wrap {
  width: 100%;
}

.login-btn {
  cursor: pointer;
}

.approve-btn,
.blacklist-btn {
  color: #ffffff;
  border: 0px;
  border-radius: 5px;
}

.approve-btn {
  background-color: green;
}

.blacklist-btn {
  background-color: rgb(163, 24, 24);
}

td > img {
  max-width: 30%;
  border-radius: 0.2857rem;
}

td > a {
  cursor: pointer;
}

.textarea {
  border-radius: 13px;
}

.modal-mask {
  display: none;
  justify-content: center;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  transition: opacity 0.3s ease;
}

div.modal-container {
  margin: auto;
  top: 300px;
  padding: 20px 30px;
  background-color: #fff;
  /* background-color: #222a3f; */
  color: black;
  /* color: white; */
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  position: relative;
  border: 5px solid #41b883;
}

div.info-modal-container {
  margin: auto;
  top: 100px;
  padding: 20px 30px;
  background-color: #fff;
  color: black;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  position: relative;
  border: 5px solid #41b883;
}

div.modal-header h3 {
  margin-top: 0;
  color: #41b883;
}

div.modal-container h5 {
  margin-top: 0;
  color: black;
}

div.modal-container h4 {
  margin-top: 0;
  color: #41b883;
}

.modal-wrapper {
  position: relative;
  left: 1em;
  top: -5em;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

button.pop-up-btn {
  margin-left: 11px;
  border: none;
  font-size: 25px;
  color: #41b883;
  background: transparent;
  /* border-radius: 5px; */
  cursor: pointer;
  /* background-color: #e14eca; */
  padding: 8px;
  /* border: 2px solid #41B883; */
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.btn-close {
  border: none;
  font-size: 28px;
  cursor: pointer;
  color: #41b883;
}

.pop-header {
  display: flex;
}

.table-responsive {
  // overflow: scroll;
  padding: 1px 26px 0px 20px;
  // overflow: auto;
  display: flex;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.action {
  cursor: pointer;
}

.pagination {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  user-select: none;

  p {
    padding: 7px 14px;
    border: rgba(91, 91, 91, 0.3) solid 1px;
    // color: #4eb95e;

    &:hover {
      cursor: pointer;
      background-color: rgba(91, 91, 91, 0.3);
    }

    &.active {
      background-color: rgba(91, 91, 91, 0.4);
    }

    &.inactive {
      color: #999999;
      pointer-events: none;
    }

    &.from {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &.to {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
</style>
<style lang="scss">
.theme--dark.v-tabs > .v-tabs-bar {
  background-color: #33374c !important;
}

.v-application--wrap {
  min-height: fit-content !important;
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #2a2d3f !important;
}

.v-sheet.v-card,
.theme--dark.v-data-table {
  background: #33364d !important;
}

.v-data-table {
  white-space: nowrap !important;
}

.label {
  margin-top: 10px;
  // display: inline-flex;
}

.checkbox {
  display: none;
}

.customCheckbox {
  margin-top: 10px;
  display: block;
  width: 20px;
  height: 20px;
  // border: 1px solid white;
  position: relative;
}

.customCheckbox::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 15px;
  height: 18px;
  // background: green;
  background-image: url("../../../admin/public/img/checkboxIcon.png");
  opacity: 1;
  transition: 0.3s;
}

// .customCheckbox:checked + .customCheckbox::before {
//   opacity: 1;
// }

// input[type="checkbox"].checked {
//   background: green;
// }
</style>
