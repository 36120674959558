<template>
  <div id="pop-up-menu-" class="modal-mask" :class="{ active: active }">
    <div class="modal-wrapper">
      <div class="modal-container">
        <h2>{{ header }}</h2>
        <p>{{ body }}</p>
        <select v-model="selected" v-if="select">
          <option value="" disabled selected>---------</option>
          <option v-for="item in select" :value="item" :key="item">{{
            item
          }}</option>
        </select>
        <div class="buttons">
          <button
            v-if="cancelBtn"
            class="button"
            type="submit"
            v-on:click="$emit('close')"
          >
            Cancel
          </button>
          <div class="action-btns">
            <button
              v-if="declineBtn"
              class="button"
              type="submit"
              v-on:click="$emit('decline')"
            >
              {{ failText ? failText : "Decline" }}
            </button>
            <button
              class="button"
              type="submit"
              v-on:click="$emit('submit', user, selected)"
            >
              {{ successText ? successText : "Okay" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "confirm-modal",
  components: {},
  props: {
    active: Boolean,
    header: String,
    body: String,
    failText: String,
    successText: String,
    select: String,
    user: String,
    declineBtn: {
      type: Boolean,
      default: true
    },
    cancelBtn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selected: ""
    };
  },
  methods: {}
};
</script>
<style lang="less">
.modal-mask {
  position: fixed;
  justify-content: center;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  transition: opacity 0.3s ease;

  &.active {
    display: flex !important;
  }

  .modal-wrapper {
    position: relative;
    top: 125px;

    .modal-container {
      width: 600px;
      margin: auto;
      padding: 20px 30px;
      background-color: #272a3d;
      color: black;
      border-radius: 5px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      font-family: Helvetica, Arial, sans-serif;
      position: relative;
      border: 2px solid #41b883;

      h2 {
        color: #41b883;
        text-transform: capitalize;
      }

      select,
      option {
        padding: 10px;
        outline: none;
        font-size: 18px;
        border-radius: 10px;
        color: white;
        border: 2px solid #41b883;
      }
      select {
        -webkit-appearance: auto;
        appearance: auto;
      }

      .buttons {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        .button {
          border: none;
          font-size: 25px;
          color: #41b883;
          background: transparent;
          cursor: pointer;
          padding: 0px !important;
        }

        .action-btns {
          .button {
            margin-left: 20px;
          }
        }
      }
    }
  }
}
</style>
