<template>
  <div class="wrapper">
    <side-bar>
      <template class="sidebar" slot="links">
        <sidebar-link
          v-if="hasPermission('dashboard')"
          to="/dashboard"
          :name="$t('sidebar.dashboard')"
          icon="fa fa-home"
        />
        <!-- <sidebar-link
          to="/covid-relief"
          :name="$t('covid Relief')"
          icon="tim-icons icon-pin"
        /> -->
        <sidebar-link
          v-if="hasPermission('verification')"
          to="/verification"
          :name="$t('planner verification')"
          icon="fa fa-check"
        />
        <!-- <sidebar-link to="users/" :name="$t('users')" icon="tim-icons icon-single-02"/> -->
        <!-- <sidebar-link to="/cms" :name="$t('CMS')" icon="tim-icons icon-bell-55"/> -->
        <sidebar-link
          v-if="hasPermission('users')"
          to="/planners"
          :name="$t('users')"
          icon="fa fa-users"
        />
        <sidebar-link
          v-if="hasPermission('sub admin')"
          to="/sub-admin"
          :name="$t('sub admin')"
          icon="fa fa-user"
        />
        <sidebar-link
          v-if="hasPermission('tickets')"
          to="/tickets"
          :name="$t('Purchases')"
          icon="fa fa-shopping-cart"
        />
        <sidebar-link
          v-if="hasPermission('events')"
          to="/manage-events"
          :name="$t('events')"
          icon="fa fa-star"
        />
        <sidebar-link
          v-if="hasPermission('promoter-sales')"
          to="/promoter-sales"
          :name="$t('promoter-sales')"
          icon="tim-icons icon-basket-simple"
        />
        <sidebar-link
          to="/financials"
          :name="$t('financials')"
          icon="tim-icons icon-money-coins"
        />
        <sidebar-link
          v-if="hasPermission('failed Transactions')"
          to="/failed-transactions"
          :name="$t('failed Transactions')"
          icon="fa fa-exclamation-circle"
        />
        <!-- <sidebar-link to="/profile" :name="$t('sidebar.userProfile')" icon="tim-icons icon-single-02"/> -->
        <!-- <sidebar-link to="/table-list" :name="$t('Interests')" icon="tim-icons icon-puzzle-10"/> -->
        <sidebar-link
          v-if="hasPermission('admin Fee')"
          to="/admin-fee"
          :name="$t('admin Fees')"
          icon="fa fa-percent"
        />
        <sidebar-link
          v-if="hasPermission('url redirects')"
          to="/url-redirects"
          :name="$t('url redirects')"
          icon="fa fa-globe"
        />
        <sidebar-link
          v-if="hasPermission('custom invoice')"
          to="/custom-invoice"
          :name="$t('custom invoice')"
          icon="fa fa-file"
        />
        <sidebar-link
          v-if="hasPermission('white label')"
          to="/white-label"
          :name="$t('white label')"
          icon="fa fa-tags"
        />
        <sidebar-link
          v-if="hasPermission('test Emails')"
          to="/test-emails"
          :name="$t('test Emails')"
          icon="fa fa-envelope"
        />
        <sidebar-link
          v-if="hasPermission('Email Logs')"
          to="/email-logs"
          :name="$t('Email Logs')"
          icon="fa fa-inbox"
        />
        <sidebar-link
          v-if="hasPermission('Tooltip')"
          to="/tool-tip"
          :name="$t('Tooltip')"
          icon="fa fa-comment"
        />
        <!-- <sidebar-link to="/advertisement" :name="$t('advertisement')" icon="tim-icons icon-components"/>  -->
        <!-- <sidebar-link to="/dashboard?enableRTL=true" :name="$t('sidebar.rtlSupport')" icon="tim-icons icon-world"/> -->
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar v-on:logout="logout"> </top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
.sidebar {
  ::-webkit-scrollbar {
    display: none;
  }
}
li.nav-item.router-link-exact-active.router-link-active a p {
  font-weight: 900;
  color: white;
}
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
import SidebarLink from "../../components/SidebarPlugin/SidebarLink.vue";
import axios from "axios";
export default {
  data() {
    return {
      permissions: []
    };
  },
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    SidebarLink
  },
  methods: {
    hasPermission(name) {
      return this.permissions && this.permissions.includes(name);
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    logout() {
      this.$emit("logout");
    }
  },
  mounted() {
    this.permissions = [
      "dashboard",
      "verification",
      "users",
      "sub admin",
      "tickets",
      "events",
      "Financials",
      "failed Transactions",
      "admin Fee",
      "url redirects",
      "custom invoice",
      "white label",
      "test Emails",
      "Email Logs",
      "Tooltip",
      "promoter-sales",
    ];
    // axios
    //   .post(`${process.env.VUE_APP_SERVICE_URL}admin/permissions`)
    //   .then(response => {
    //     let data = response.data;
    //     this.permissions = data.permissions;
    //   });
  }
};
</script>
