import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import * as Sentry from "@sentry/vue";
import App from "./App";
import router from "./router/starterRouter";
import store from "./store";
import Axios from "axios";
import { LoaderManager } from "./router/helpers/loader";

import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import VueToastification, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.prototype.$axios = Axios;
Vue.prototype.$loader = new LoaderManager(store);

Axios.defaults.baseURL = process.env.VUE_APP_SERVICE_URL.slice(0, -1);

Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(VueToastification, {
  position: POSITION.BOTTOM_LEFT,
  maxToasts: 3,
  newestOnTop: true
});

Sentry.init({
  Vue,
  dsn:
    "https://ab042664e3e247079cb2364361a17997@o1081988.ingest.sentry.io/6090007",
  environment: process.env.VUE_APP_ENVIRONMENT,
  // integrations: [
  //   new Integrations.BrowserTracing({
  //     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  //     tracingOrigins: ["localhost", "admin.incredevent.com", "admin.staging.incredevent.com", /^\//],
  //   }),
  // ],
  tracesSampleRate: 0.25,
  logErrors: true,
  enabled: process.env.NODE_ENV !== "development"
});

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
