import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    session_id: null,
    user: { isAuthenticated: false },
    count: 0,
    loading: false
  },
  mutations: {
    
  },
  actions: {

  }
})
